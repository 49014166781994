var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"Container_Nivel2_Apuracao",attrs:{"fluid":"","px-0":""}},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"Barra_Pesquisa",attrs:{"append-icon":"search","label":("" + (_vm.$tc('label.pesquisar', 1))),"single-line":"","hide-details":"","clearable":""},on:{"input":_vm.filtrar},model:{value:(_vm.pesquisaCards),callback:function ($$v) {_vm.pesquisaCards=$$v},expression:"pesquisaCards"}})],1),(_vm.lista.length)?_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-data-iterator',{attrs:{"items":_vm.lista,"options":_vm.pagination,"hide-default-footer":12 >= _vm.totalPage,"server-items-length":_vm.totalPage,"footer-props":{
            itemsPerPageOptions: _vm.rowsPerPageItems,
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',{attrs:{"justify":"start"}},_vm._l((props.items),function(item){return _c('v-col',{key:item.cod_acao_previa,attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-card',{staticClass:"clickable Card_Apuracao",class:("Card_" + (item.status) + " elevation-" + (hover ? 5 : 1)),attrs:{"fill-height":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-card-title',_vm._g({staticClass:"Card_Title",on:{"click":function($event){return _vm.selectItem(item)}}},on),[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.foco_id,25))+" "),_c('span',[_c('br'),_vm._v(_vm._s(_vm._f("truncate")(item.foco_nome,25))+" "),_c('br'),_vm._v(_vm._s(item.cod_acao_previa ? ("Cod. Planejamento Massivo: " + (item.cod_acao_previa)) : ("Cod. Ação Tática: " + (item.cod_acao)))+" "),_c('br'),_c('br'),_vm._v(_vm._s(_vm.$tc('label.periodo_apuracao', 1))+" "),_c('br'),_vm._v(_vm._s(item.data_inicio)+" à "+_vm._s(item.data_fim)+" "),_c('br'),_c('div',{staticClass:"headline font-weight-light",attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.getMoney(item.vlr_conf_apuracao))+" ")])])])])],1)]}}],null,true)},[((_vm.isVisaoStatus && (item.apuracoes_aprovadas === 0)
                        && (item.apuracoes_reprovadas === 0)
                        && (item.apuracoes_canceladas === 0)) )?_c('span',[_vm._v(" Status: "+_vm._s(_vm.$tc('status_entidade.'+item.status.toLowerCase()))+" ")]):_vm._e(),((!_vm.isVisaoStatus && (item.apuracoes_aprovadas === 0)
                        && (item.apuracoes_reprovadas === 0)
                        && (item.apuracoes_canceladas === 0)) )?_c('span',[_vm._v(" Ainda não existem apurações aprovadas, reprovadas ou canceladas ")]):_vm._e(),(((item.apuracoes_aprovadas === item.quantidade_acoes)
                        && (item.quantidade_acoes > 0)
                        && (item.apuracoes_reprovadas === 0)
                        && (item.apuracoes_canceladas === 0)) )?_c('span',[_vm._v(" Status: Apurações Aprovadas ")]):_vm._e(),(((item.apuracoes_reprovadas === item.quantidade_acoes)
                        && (item.quantidade_acoes > 0)
                        && (item.apuracoes_aprovadas === 0)
                        && (item.apuracoes_canceladas === 0)) )?_c('span',[_vm._v(" Status: Apurações Reprovadas ")]):_vm._e(),(((item.apuracoes_canceladas === item.quantidade_acoes)
                        && (item.quantidade_acoes > 0)
                        && (item.apuracoes_reprovadas === 0)
                        && (item.apuracoes_aprovadas === 0)) )?_c('span',[_vm._v(" Status: Apurações Canceladas ")]):_vm._e(),(((item.apuracoes_aprovadas > 0)
                        && ((item.apuracoes_aprovadas !== item.quantidade_acoes) ||
                            ((item.apuracoes_reprovadas > 0) || (item.apuracoes_canceladas > 0)))
                        && (item.apuracoes_aprovadas >= item.apuracoes_reprovadas)
                        && (item.apuracoes_aprovadas >= item.apuracoes_canceladas)) )?_c('span',[_vm._v(" Status: Parcialmente Aprovado "),((item.apuracoes_reprovadas > 0) || (item.apuracoes_canceladas > 0))?_c('p',[_vm._v(" Existem apurações reprovadas ou canceladas ")]):_vm._e()]):_vm._e(),(((item.apuracoes_reprovadas > item.apuracoes_aprovadas)
                        && ((item.apuracoes_reprovadas !== item.quantidade_acoes) || (item.apuracoes_aprovadas > 0))
                        && (item.apuracoes_reprovadas >= item.apuracoes_canceladas)) )?_c('span',[_vm._v(" Status: Parcialmente Reprovado "),(item.apuracoes_aprovadas > 0)?_c('p',[_vm._v(" Existem apurações aprovadas ")]):_vm._e()]):_vm._e(),(((item.apuracoes_canceladas > item.apuracoes_aprovadas)
                        && ((item.apuracoes_canceladas !== item.quantidade_acoes) || item.apuracoes_aprovadas > 0)
                        && (item.apuracoes_canceladas >= item.apuracoes_reprovadas)) )?_c('span',[_vm._v(" Status: Parcialmente Cancelado "),(item.apuracoes_aprovadas > 0)?_c('p',[_vm._v(" Existem apurações aprovadas ")]):_vm._e()]):_vm._e()]),_c('v-card-text',{staticClass:"Card_Content py-0",on:{"click":function($event){return _vm.selectItem(item)}}},[(_vm.isVisaoStatus)?_c('span',[_vm._v(" "+_vm._s(((item.quantidade_acoes > 0 ? item.quantidade_acoes : 1) + " " + (_vm.retornaTextoStatus(item.quantidade_acoes, item.status))))+" ")]):_c('span',[_vm._v(" "+_vm._s(((item.quantidade_acoes > 0 ? item.quantidade_acoes : 1) + " " + (item.quantidade_acoes > 1 ? 'Ações' : 'Ação')))+" ")])]),(item.cod_acao_previa)?_c('v-card-actions',{staticClass:"Card_Actions"},[(_vm.canAccessAprovMass && ((item.apuracoes_aguardando_aprovacao > 0) || (item.apuracoes_aguardando_apuracao > 0)
                          || (item.apuracoes_em_analise > 0) || (item.apuracoes_vencidas > 0)))?_c('apuracao-acao-massiva-fluxo',{staticClass:"-3",attrs:{"idAcaoMassiva":item.cod_acao_previa,"status":item.status},on:{"AprovacaoMassivaApuracao_sucesso":_vm.buscar,"LimpaSelecionados_sucesso":function () {}}}):_vm._e()],1):_vm._e()],1)]}}],null,true)})],1)}),1)]}},{key:"no-data",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('label.tabela_sem_conteudo'))+" ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.$t('label.tabela_sem_conteudo'))+" ")])]},proxy:true}],null,false,390011336)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }